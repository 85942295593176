
import {Component, Prop, Ref, Vue} from 'vue-property-decorator';
import errorHandler from "../error/error-handler";

const DEFAULT_MAX_FILE_SIZE = 100 * 1024 * 1024

@Component
export default class DocumentUpload extends Vue {
  @Ref("fileInput") readonly fileInput!: HTMLInputElement

  @Prop() public file!: File | null;
  @Prop() public acceptableMimeTypes!: string[]
  @Prop() public acceptableExtensions!: string
  @Prop({ default: DEFAULT_MAX_FILE_SIZE }) public acceptableFileSize!: number;

  dragover = false;


  private onDrop(e: any) {
    this.dragover = false;
    const files = e.dataTransfer.files;
    if (files.size > 1) {
      errorHandler.showWarning("document-submission.only-one-file");
    }
    this.validateFileAndGetMetadata(files[0]);
  }

  private onFileSelected(e: any) {
    const file = e.target.files[0]
    if (file) {
      this.validateFileAndGetMetadata(file);
    }
  }

  private reset() {
    this.fileInput.value = "";
    this.$emit('clearFileRelatedData');
  }

    private static isMimeTypeValid(type: string, acceptableTypes: string[]) {
        return acceptableTypes.length === 0 || acceptableTypes.includes(type);
    }

  private validateFileAndGetMetadata(file: File) {
    this.reset();
      if (!DocumentUpload.isMimeTypeValid(file.type, this.acceptableMimeTypes)) {
          errorHandler.showError("error.file.invalid-file-type");
      } else if (file.size > this.acceptableFileSize) {
      errorHandler.showError("error.file.invalid-file-size");
    } else {
      this.$emit('updateFile', file);
    }
  }
}
