
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  components: {}
})
export default class FileUploaderInfoBox extends Vue {
  @Prop() public isLastSubmitSuccessful!: boolean;
  @Prop() public successfulMessageCode!: string;
  @Prop() public unsuccessfulMessageCode!: string;
}
