import http from "../plugins/http";

export default class CompanyFormValidatorService {

    private readonly contentType = {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    };

    public submit(formData: FormData) {
        return http.post(`/api/form-validation`, formData, this.contentType)
    }
}
