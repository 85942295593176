
import {Component, Vue} from 'vue-property-decorator';
import DocumentUpload from "../components/DocumentUpload.vue";
import CustomPageTitle from "../components/CustomPageTitle.vue";
import CompanyFormValidatorService from "../service/company-form-validator-service";
import {MimeTypes} from "../common/mime-types";
import FileUploaderInfoBox from "../components/FIleUploaderInfoBox.vue";

@Component({
    components: {
        CustomPageTitle,
        DocumentUpload,
        FileUploaderInfoBox
    }
})
export default class CompanyFormValidator extends Vue {

    public validatorService = new CompanyFormValidatorService();

    private file: File | null = null;
    private acceptableMimeTypes = [MimeTypes.XML_TYPE]
    private lastSubmitSuccessful: boolean | null = null;
    private responseMessage = null;

    get isSendDisabled() {
        return !this.file;
    }

    get isLastSubmitSuccessful() {
        return this.lastSubmitSuccessful;
    }

    get isValidCompanyFormCheck(): boolean {
        return this.responseMessage != null && this.responseMessage == 0;
    }

    private submit() {
        const formData = new FormData();

        formData.append("file", this.file as Blob);
        this.lastSubmitSuccessful = null;

        this.validatorService.submit(formData).then(
            (response) => {
                this.responseMessage = response.data;
            },
            () => {
                this.responseMessage = null;
                this.lastSubmitSuccessful = false;
            });
    }

    private updateFile(value: File | null) {
        this.file = value;
        this.responseMessage = null;
        this.lastSubmitSuccessful = null;
    }

    private clearSubmissionFileRelatedData() {
        this.updateFile(null);
    }
}
